console.log("REACT_APP_ENVIRONMENT", process.env.REACT_APP_ENVIRONMENT);
console.log("REACT_APP_RUNNING_IN", process.env.REACT_APP_RUNNING_IN);

const amplifyAuthConfig = {
	Cognito: {
		identityPoolId: "us-east-2:44ee4b81-7358-4427-931c-9b6da38a1f7e",
		region: "us-east-2",
		userPoolId: "us-east-2_TmaYvXjlY",
		userPoolClientId: "63f24ssjit74atpuqhq0bisaec",
		mandatorySignIn: true,
		loginWith: {
			username: true,
			email: true,
		},
	},
};

const endpoints = {
	production:
		"https://k7i5zuhixe.execute-api.us-east-2.amazonaws.com/production",
	staging: "https://k7i5zuhixe.execute-api.us-east-2.amazonaws.com/staging",
	development: "https://k7i5zuhixe.execute-api.us-east-2.amazonaws.com/Dev",
	newfeature:
		"https://k7i5zuhixe.execute-api.us-east-2.amazonaws.com/newfeature",
	onboarding:
		"https://k7i5zuhixe.execute-api.us-east-2.amazonaws.com/onboarding",
	localstack: `https://lexworkplace.local:4566/restapis/lxw_localstack_api/test/_user_request_`,
};

// const commonAuthConfig = {
// 	identityPoolId: "us-east-2:44ee4b81-7358-4427-931c-9b6da38a1f7e",
// 	region: "us-east-2",
// 	userPoolId: "us-east-2_TmaYvXjlY",
// 	userPoolWebClientId: "63f24ssjit74atpuqhq0bisaec",
// 	mandatorySignIn: true,
// };

const production_aws_config = {
	Auth: amplifyAuthConfig,
	API: {
		REST: {
			"production-authorized": {
				endpoint: endpoints.production,
			},
			"production-public": {
				endpoint: endpoints.production,
			},
		},
	},
};
const staging_aws_config = {
	Auth: amplifyAuthConfig,
	API: {
		REST: {
			"staging-authorized": {
				endpoint: endpoints.staging,
			},
			"staging-public": {
				endpoint: endpoints.staging,
			},
		},
	},
};
const dev_aws_config = {
	Auth: amplifyAuthConfig,
	API: {
		REST: {
			"development-authorized": {
				endpoint: endpoints.development,
			},
			"development-public": {
				endpoint: endpoints.development,
			},
		},
	},
};
const newfeature_aws_config = {
	Auth: amplifyAuthConfig,
	API: {
		REST: {
			"newfeature-authorized": {
				endpoint: endpoints.newfeature,
			},
			"newfeature-public": {
				endpoint: endpoints.newfeature,
			},
		},
	},
};
const local_aws_config = {
	Auth: amplifyAuthConfig,

	API: {
		// all endpoints are available locally, in case you want to talk to
		// various backends while running the frontend locally
		REST: {
			"localstack-authorized": {
				endpoint: endpoints.localstack,
			},
			"localstack-public": {
				endpoint: endpoints.localstack,
			},
			"development-authorized": {
				endpoint: endpoints.development,
			},
			"development-public": {
				endpoint: endpoints.development,
			},
			"newfeature-authorized": {
				endpoint: endpoints.newfeature,
			},
			"newfeature-public": {
				endpoint: endpoints.newfeature,
			},
			"staging-authorized": {
				endpoint: endpoints.staging,
			},
			"staging-public": {
				endpoint: endpoints.staging,
			},
			"production-authorized": {
				endpoint: endpoints.production,
			},
			"production-public": {
				endpoint: endpoints.production,
			},
			"onboarding-authorized": {
				endpoint: endpoints.onboarding,
			},
			"onboarding-public": {
				endpoint: endpoints.onboarding,
			},
		},
	},
};
const config = (() => {
	switch (process.env.REACT_APP_ENVIRONMENT) {
		case "production":
			return production_aws_config;
		case "staging":
			return staging_aws_config;
		case "development":
			return dev_aws_config;
		case "newfeature":
			return newfeature_aws_config;
		default:
			return local_aws_config;
	}
})();

export default { ...config };
