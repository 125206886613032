import React, { useState, useRef, useCallback } from "react";

import { LabeledDropDownSelect } from "./DropDownSelect";

import { fetchMattersListFromClioNoRedux } from "../actions/clioIntegrationActions";

const makeClioMatterDropdownItem = ({
	display_number,
	id,
	linkedLxwMatterID,
}) => {
	return {
		label: (
			<div>
				<div>{display_number}</div>
				{linkedLxwMatterID ? (
					<>
						(Already Linked)
						<a
							target="_blank"
							href={`${window.location.origin}/matters/${linkedLxwMatterID}/docs`}
							rel="noreferrer"
						>
							{" "}
							Open Linked Matter
						</a>
					</>
				) : null}
			</div>
		),
		value: display_number,
		id: id,
		isDisabled: linkedLxwMatterID ? true : false,
	};
};

const ClioMatterSelector = ({ debounceTimeMS = 500, ...props }) => {
	const [totalMatchingClioMatters, setTotalMatchingClioMatters] = useState(0);

	const debounceTimeout = useRef();

	const loadClioMatters = useCallback((matterFilterValue) => {
		return fetchMattersListFromClioNoRedux(matterFilterValue).then(
			({ clioMatterList, totalMatchingMatters }) => {
				if (totalMatchingMatters !== undefined) {
					setTotalMatchingClioMatters(totalMatchingMatters);
				}
				if (clioMatterList) {
					return clioMatterList
						.map((m) => {
							//clio matter IDs look like timestamps in seconds
							// something like: 1669549204
							// for sorting we want the "already linked" matters
							// sorted below the non-linked matters
							const sortNum =
								parseInt(m.id, 10) +
								(m.linkedLxwMatterID ? -10000000000 : 0);

							return { ...m, sortNum };
						})
						.sort((a, b) => {
							return b.sortNum - a.sortNum;
						});
				}
				return [];
			}
		);
	});

	const debouncedClioApiCall = useCallback(
		(value) => {
			return new Promise((resolve, reject) => {
				// use a debounce timer so that the async call
				// (eg a request to a server) doesn't execute
				// on every keystroke
				clearTimeout(debounceTimeout.current);

				debounceTimeout.current = setTimeout(() => {
					loadClioMatters(value).then((x) =>
						resolve(x.map(makeClioMatterDropdownItem))
					);
				}, debounceTimeMS);
			});
		},
		[debounceTimeMS]
	);

	return (
		<LabeledDropDownSelect
			{...props}
			isAsync
			loadOptions={debouncedClioApiCall}
			defaultOptions //this tells react-select to call the loadOptions function on init
			totalResults={totalMatchingClioMatters}
		/>
	);
};

export { ClioMatterSelector };
